import './openpage.css';

const OpenPage = ({OnRouteChange, onGame, user})=>{
	return(
		<div>
		<article className="gameCard center br3 ba b--black-10 w-100 w-50-m w-25-1 mw6 shadow-5">
			<main className='pa4 black-80'>
				<div className='measure'>
					<fieldset id='sign_up' className='ba b--transparent ph0 mh0'>
						<legend className='f1 fw6 ph0 mh0'>{user.name}</legend>
						<div className="detalhes center">
							<div>
								<p><label>Partidas</label></p>
								<p><label>{user.entries}</label></p>
							</div>
							<div>
								<p><label>Wins against PC</label></p>
								<p><label>{user.wins}</label></p>
							</div>
						</div>
						<div className='tc btn-opt'>
						<button id='game' className='.btngame ma4' onClick={onGame}>Jogar</button>
						<button id='gamepc' className='.btngame' onClick={()=>onGame('pc')}>Jogar contra Pc</button>
						</div>
					</fieldset>
				</div>
			</main>
		</article>
		</div>
	)
}

export default OpenPage;