import React,{useState, useEffect} from 'react';
import Box from './box';

import Hr from './engambela';
const {pecas, sair, pcAdversary, setUserId} = require('../../logic');


function onClickSair(){
	sair();
	return 'sair';
}


const GameBoard = ({getWins, onGame, adversary, user})=>{

	if(adversary === 'pc') {
		setUserId(user.id);
		pcAdversary();
	}

	
	const [board, setBoard] = useState(document.getElementById('gmc'));
	useEffect(()=>{
	    if(!board)setBoard(document.getElementById('gmc'));

	},[]);
	
	return(
	  <div className="pg-geral">
		<div id="gmc" className="game-container">
			{
				pecas(board).map((t, i)=> {return <Box key={i} i={i}/>})
			}

		</div>
		<div className='btn-container'>
              <button id='btn' onClick={()=>onGame(adversary)} className='btn input wave waveA'>Play Again?</button>
            </div> 
            <h1 id='idH1' className='winner'> </h1>
            <Hr />
		<div className='sair lh-copy mt3'>
			<p onClick={()=>getWins(onClickSair())} className='f6 link dim black db pointer'>Sair</p>
		</div>
      </div>
	);
}

//()=>onRouteChange('sair')


export default GameBoard;