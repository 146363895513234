import React, {useState, useEffect} from 'react';
import './App.css';
import Login from './components/login/Login';
import Register from './components/register/Register'
import GameBoard from './components/gameboard/gameboard';
import OpenPage from './components/openPage/OpenPage';


const initialUser = {
    id: '',
    name: '',
    email: '',
    entries: 0,
    wins: 0,
    joined: ''
  }




function App() {
  const [user, setUser] = useState(initialUser);
  const [init, setInit] = useState(true);
  const [adversary, setAdversary] = useState('person');
  const [route, setRoute] = useState('signin');

  const getWins = (rout)=>{
    if(user.id !== ''){
      fetch('https://jogo-da-velha-api.onrender.com/getwins', {
          method: 'post',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            id: user.id
          })
        })
        .then(response => response.json())
        .then(wins => {
            if (wins) {
              setUser(Object.assign(user, {wins: wins.wins}));
              OnRouteChange(rout);
            }
          }).catch(error => console.log('error', error));
    }
  };

  const onGame = (event)=>{
    if(event === 'pc' || adversary === 'pc') {

      fetch('https://jogo-da-velha-api.onrender.com/partidas', {
          method: 'put',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            id: user.id
          })
        })
          .then(response => response.json())
          .then(entries => {
            if (entries) {
              setUser(Object.assign(user, {entries: entries}));
            }
          }).catch(error => console.log('error', error));
        setAdversary(event);
    }
    else setAdversary('person');
    setInit(false);
  }


  const loadUser = (data) => {
    Object.assign(user, data);
  }

  const OnRouteChange = (rout)=>{
    if(rout === 'sair'){
      setRoute('home');
      setInit(true);
    }else if(rout === 'home'){
      setRoute(rout);
    }else{
      setRoute(rout);
    }
  }


  return (
    <div className="App">
      <h1>TIC TAC TOE</h1>
      { route === 'home'
        ?(
          init?
            <OpenPage onRouteChange={OnRouteChange} onGame={onGame} user={user}/>
          :(
            <div>
            <GameBoard getWins={getWins} onGame={onGame} adversary={adversary} user={user}/>
            
            </div>
          )
        ):
          route === 'signin'
            ?<Login loadUser={loadUser} onRouteChange={OnRouteChange}/>
            :<Register loadUser={loadUser} onRouteChange={OnRouteChange}/>

      }
    </div>
  );
}

export default App;

