

const TURN = {
	X: 'X',
	O: 'O'
}

let turn = TURN.X;
let cont = 0;
const EMPATE = 9;
let player = true;
let playerpc = false;
let userId = 0;
const setUserId = (id)=>{
	userId = id;
};


const pecas = (board)=>{
	main(board);
	let i = 0;
	let tag = [];
	while(i<9) tag.push(++i);
	return tag; 
}

const RunGame = (event) => {
	let winner = checkWinner();
	if(player && !winner){
		const boxId = event.target.id;
		GameManager(boxId, player, playerpc);
		
	}
	if(!winner && cont !== EMPATE)
		while(!player)
			GameManager(pegaBoxId(), player, playerpc);
}

const pegaBoxId =()=>{
	return (`box-${Math.floor((Math.random()*10))}`);
}

const GameManager = (boxId, player1, playerpc)=>{
	let winner = checkWinner();
	if(!winner){
		const box = document.getElementById(boxId);
		if((box === null || box.textContent !== "" || boxId === 'gmc')) return;
		box.textContent = turn;
		++cont;
		changeBoxBackground(box);
		winner = checkWinner();
		if(!winner && cont !== EMPATE) turn = SwichTurn(turn);
		else if(!winner && cont === EMPATE){
			const button = document.getElementById('btn');
			const board = document.getElementsByClassName('game-container')[0];
			const winMessage = document.getElementById('idH1');
			if(playerpc)winMessage.textContent = 'You Loose!!!';
			else winMessage.textContent = 'Don`t have Winner!!!';
			endGame(button, board, winMessage);
		} 
		else if(winner){
			const button = document.getElementById('btn');
			const board = document.getElementsByClassName('game-container')[0];
			const winMessage = document.getElementById('idH1');
			winMessage.textContent = `Winner is ${turn}!!!`;
			endGame(button, board, winMessage);
			if(player && playerpc){
				IncrementWins(userId);
			}
		}
	}
	if(playerpc) player = SwichTurnPlayer(player1);
}

const IncrementWins = (id)=>{
	return fetch('https://jogo-da-velha-api.onrender.com/wins', {
            method: 'put',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
              id: id
            })
          })
            .then(response => response.json())
            
}

const SwichTurnPlayer = (player1) =>{
	if(player1) return false;
	return true;
}

function endGame(button, board, winMessage){
	button.style.display = 'block';
	button.style.visibility = 'visible';
	button.addEventListener('click', resetGame);
	if(winMessage === null) return;
	winMessage.style.display='block';
}

const resetGame = ()=>{
	cont = 0;
	const winMessage = document.getElementById('idH1');
	const button = document.getElementById('btn');
	const board = document.getElementsByClassName('game-container')[0];
	if(board){
		turn = TURN.X;
		resetBoxes();
		button.style.visibility = 'hidden';
		winMessage.textContent = "";
		player = true;
		//board.addEventListener('click', RunGame);
	}

}

const sair = ()=>{
	if(playerpc) playerpc = false;
	resetGame();
}

function resetBoxes(): void{
	for(let i = 0; i < 9; i++){
		const box = document.querySelector(`#box-${i}`);
		box.textContent = "";
		const boxClass = box.className;
		box.classList.remove(boxClass);
		void box.offsetWidth;
		box.classList.add('box');
	}
}

const changeBoxBackground = (box)=>{
	if(turn === TURN.X) box.classList.replace('box', 'x');
	else box.classList.replace('box', 'o');
}

const SwichTurn = (pc) =>{	
    if(pc === TURN.X) pc = TURN.O;
    else pc = TURN.X;

    return pc;
  }

  function checkWinner(): boolean{
	const boxes = getBoxes();
	return(
		(boxes[0] === boxes[1] && boxes[1] === boxes[2] && boxes[0] !== "") ||
		(boxes[3] === boxes[4] && boxes[4] === boxes[5] && boxes[3] !== "") ||
		(boxes[6] === boxes[7] && boxes[7] === boxes[8] && boxes[6] !== "") ||
		(boxes[0] === boxes[4] && boxes[4] === boxes[8] && boxes[0] !== "") ||
		(boxes[2] === boxes[4] && boxes[4] === boxes[6] && boxes[2] !== "") ||
		(boxes[1] === boxes[4] && boxes[4] === boxes[7] && boxes[1] !== "") ||
		(boxes[0] === boxes[3] && boxes[3] === boxes[6] && boxes[0] !== "") ||
		(boxes[2] === boxes[5] && boxes[5] === boxes[8] && boxes[2] !== "") 
	);
}

function getBoxes(){
	const boxesContent = [];
	for(let i = 0; i < 9; i++){
		const box = document.querySelector(`#box-${i}`);
		const boxContent = box?.textContent;
		if(boxContent === null) boxesContent.push("");
		else{
			boxesContent.push(boxContent)
		}
	}
	return boxesContent;
}

const pcAdversary = ()=>{
	playerpc = true;
}


const main = (board)=>{
	if(board) board.addEventListener("click", RunGame);
}

module.exports = {pecas: pecas, sair: sair, pcAdversary: pcAdversary, setUserId: setUserId}
